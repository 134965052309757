.backdrop {
    position: fixed;
    top: 0;
    left: 7.5rem;
    right: 0;
    width: calc(100% - 7.5rem);
    height: 100vh;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.5s;
    animation: fadein 0.5s forwards;
    overflow: scroll;
    z-index: 99;
}

.modal {
    width: 100%;
    max-width: 70rem;
    margin: 5rem auto;
    padding: 2.5rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    animation: enter 0.5s;
}

.small {
    @extend .modal;
    max-width: 50rem;
}

.disappear {
    animation: exit 0.5s forwards;
}

.fadeout {
    animation: unset;
    background-color: rgba(0, 0, 0, 0);
}

@keyframes fadein {
    to {
        backdrop-filter: blur(2px);
        background-color: rgba(0, 0, 0, 0.2);
    }
}

@keyframes enter {
    0% {
        transform: translateY(2rem);
        opacity: 0;
    }
    80% {
        opacity: 1;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes exit {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    100% {
        transform: translateY(2rem);
        opacity: 0;
    }
}