.bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
}

.actions {
    display: flex;
    align-items: center;

    button {
        margin-right: 1rem;

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.back {
    display: flex;
    align-items: center;
    margin-left: -0.4rem;
    padding: 0;
    transition: color 0.2s;
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--color-2-dark);
    cursor: pointer;

    &:hover {
        color: var(--color-1);
    }
}