.wrapper {
    width: 100%;
    height: 8.5rem;
    display: flex;
    align-items: flex-start;
    margin-top: 2rem;
    padding: 1.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-10-lighter);
}

.icon {
    width: 5.5rem;
    height: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
    border-radius: var(--border-radius);
    background-color: var(--color-10);

    img {
        height: 70%;
        margin-left: 2px;
    }
}

.description {
    div {
        margin-bottom: 0.5rem;
        letter-spacing: 1px;
        font-size: 1rem;
        font-weight: 600;
        color: var(--color-2-dark);
    }

    h6 {
        color: var(--color-10);
    }

    p {
        margin-top: 0.2rem;
        font-size: 1.4rem;
    }
}

.success {
    @extend .wrapper;
    background-color: var(--color-5-light);

    .icon {
        background-color: var(--color-5);
    }

    .description {
        h6 {
            color: var(--color-5);
        }
    }
}

.failure {
    @extend .wrapper;
    background-color: var(--color-4-light);

    .icon {
        background-color: var(--color-4);
    }

    .description {
        h6 {
            color: var(--color-4);
        }
    }
}