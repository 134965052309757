.inputField {
    position: relative;
    width: 100%;
    padding: 0 1rem;
    border-radius: var(--border-radius);
    box-shadow: 0 0 0 1px var(--color-2-darker);
    -webkit-appearance: none;
    transition: box-shadow 0.2s;
    font-size: 1.6rem;
    color: var(--color-1);
    outline: none;

    &::placeholder {
        color: var(--color-2-dark);
    }

    &:focus {
        box-shadow: 0 0 0 1px var(--color-3-light), 0 0 0 5px var(--color-2-darker);
    }

    &:disabled {
        font-weight: 500;
        color: var(--color-2-dark);
    }
}

.invalid {
    box-shadow: 0 0 0 1px var(--color-4);

    &:focus {
        box-shadow: 0 0 0 1px var(--color-4), 0 0 0 5px var(--color-4-light);
    }
}

.large {
    @extend .inputField;
    height: 5rem;
}