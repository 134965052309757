.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    border-radius: var(--border-radius);
    border: 2px solid var(--color-2-darker);

    h4 {
        margin-bottom: 1rem;
        color: var(--color-1);
    }

    p {
        margin-bottom: 2rem;
        font-weight: 500;
        color: var(--color-2-dark);
    }
}

.icon {
    width: 7rem;
    height: 7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    border-radius: 50%;
    background-color: var(--color-2-darker);
    font-size: 4rem;
    color: var(--color-3);
}