.row {
    margin-bottom: 2.5rem;

    input {
        margin: 0.7rem 0;
    }

    p {
        font-size: 1.4rem;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.flexWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h6 {
        margin-bottom: 0.2rem;
    }

    p {
        margin-right: 1rem;
        font-size: unset;
    }
}

.dangerZone {
    position: relative;
    width: 100%;
    height: 2px;
    margin: 2.5rem 0;
    border: none;
    background-color: var(--color-4-light);
    overflow: visible;

    &:after {
        content: "Danger Zone";
        position: absolute;
        top: -0.7rem;
        left: 2rem;
        padding: 0 1rem;
        background-color: var(--color-2);
        font-weight: 500;
        font-size: 1.4rem;
        color: var(--color-4);
    }
}