.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
}

.description {
    padding-right: 1rem;

    h6 {
        margin-bottom: 0.2rem;
    }

    p {
        color: var(--color-2-dark);
    }
}