@import "stylesheets/animations";

.sidebar {
    position: relative;
    width: 7.5rem;
    min-height: 100%;
    flex-shrink: 0;
    padding: 0 2rem;
    background-color: var(--color-1);
    animation: slideIn 0.7s forwards;
    z-index: 10;
}

.content {
    position: sticky;
    top: 1.5rem;
    left: 0;
    width: 100%;
    height: calc(100vh - 3rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 2.5rem;
    color: var(--color-2-dark);
}

.logo {
    width: 100%;
    height: auto;
    margin: 0 0 11rem 0.5rem;
}

.menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    li {
        margin-bottom: 4rem;
        transition: color 0.2s;
        cursor: pointer;

        &:hover {
            color: var(--color-2);
        }
    }
}

.active {
    color: var(--color-2);
}

.documentation {
    transition: color 0.2s;
    color: currentColor;
    cursor: pointer;

    &:active,
    &:focus {
        color: currentColor;
    }

    &:hover {
        color: var(--color-2);
    }
}