.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
}

.description {
    width: 100%;

    h6 {
        margin-bottom: 0.2rem;
    }

    p {
        color: var(--color-2-dark);
    }

    input {
        margin-top: 1.5rem;
    }
}

.deleteButton {
    display: flex;
    align-items: center;

    > div {
        margin-right: 0.5rem;
    }
}