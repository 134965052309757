.visitedPages {
    width: 100%;
    height: 35rem;
    margin-bottom: 3rem;
    padding: 2.5rem;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);

    h3 {
        margin-bottom: 1rem;
    }

    ul {
        width: 100%;
        height: calc(100% - 5rem);
        overflow: auto;
    }

    li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1.5rem 0.5rem;
        border-top: 1px solid var(--color-2-darker);
        transition: color 0.2s;
        font-weight: 500;

        &:first-of-type {
            border-top: none;
        }

        &:hover {
            color: var(--color-3);
        }
    }
}