.card {
    height: 100%;
    padding: 2.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-2);
    box-shadow: var(--shadow);
}

.chart {
    position: relative;
    width: 100%;
    height: 100%;
}