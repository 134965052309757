.menu {
    width: 100%;
    height: 4rem;
    display: flex;
    margin-bottom: 4rem;
    box-shadow: 0 2px 0 0 var(--color-2-darker);
}

.tab {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    box-shadow: 0 2px 0 0 var(--color-2-darker);
    transition: box-shadow 0.2s, color 0.2s;
    font-weight: 500;
    cursor: pointer;

    &:last-of-type {
        margin-right: 0;
    }

    &:hover {
        box-shadow: 0 2px 0 0 var(--color-3);
        color: var(--color-3);
    }
}

.active {
    @extend .tab;
    box-shadow: 0 2px 0 0 var(--color-3);
    color: var(--color-3);
}