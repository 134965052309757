.row {
    margin-bottom: 2.5rem;

    input {
        margin: 0.7rem 0;
    }

    p {
        font-size: 1.4rem;
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}