.adapter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-right: 1rem;
    border-radius: var(--border-radius);
    background-color: var(--color-2-darker);

    img {
        width: 65%;
        height: auto;
        flex-shrink: 0;
    }
}

.small {
    @extend .adapter;
    width: 2.4rem;
    height: 2.4rem;
}

.medium {
    @extend .adapter;
    width: 3rem;
    height: 3rem;
}

.large {
    @extend .adapter;
    width: 7rem;
    height: 7rem;
    border-radius: 2rem;

    img {
        width: 55%;
    }
}

.nodejs {
    background-color: var(--color-5-lighter);
}

.custom,
.browser {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-1);
}

.custom {
    background-color: var(--color-3);

    img {
        margin-left: 15%;
    }
}