.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
}

.services {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    grid-column-gap: 1.5rem;
    grid-row-gap: 1rem;
}

.service {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem 1rem 0;
    padding: 4rem 3rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-2-darker);
    background-color: var(--color-2);
    transition: box-shadow 0.2s;
    cursor: pointer;

    div:nth-child(2) {
        width: 100%;
        flex-shrink: 0;
        margin: 2.5rem 0 1rem 0;
        text-align: center;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
        color: var(--color-1);
        overflow: hidden;
    }

    p {
        height: 7rem;
        text-align: center;
        font-size: 1.4rem;
        color: var(--color-2-dark);
    }

    &:hover {
        box-shadow: 0 0 0 2px var(--color-2-darker);
    }
}

.title {
    margin-bottom: 3rem;
}

.label {
    margin-bottom: 0.7rem;
}

.split {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.cell {
    width: 50%;

    &:first-of-type {
        margin-right: 2rem;
    }
}

.controls {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 4rem;

    button:first-of-type {
        margin-right: 1rem;
    }
}