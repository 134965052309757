.toast {
    position: fixed;
    right: 0;
    bottom: 5rem;
    display: flex;
    align-items: center;
    padding: 2rem;
    border-bottom-left-radius: var(--border-radius);
    border-top-left-radius: var(--border-radius);
    background-color: var(--color-4-light);
    transform: translateX(100%);
    transition: transform 2s;
    font-size: 2rem;
    color: var(--color-4);
    z-index: 99;

    span {
        margin-left: 1rem;
        font-size: 1.6rem;
    }
}

.open {
    transform: translateX(0);
}