.evolution {
    h4 {
        margin-top: 4rem;
        color: var(--color-1);
    }
}

.chart {
    width: 100%;
    height: 30rem;
    margin-top: 2.5rem;
    border-radius: var(--border-radius);
    background-color: var(--color-2);
    box-shadow: var(--shadow);
}